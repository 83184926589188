import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import PageList from '../components/PageList';
import { Helmet } from 'react-helmet';
import { Row, Col, Badge, ListGroup, ListGroupItem } from 'reactstrap';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import _ from 'lodash';

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  var tags = data.tags.group;
  _.pullAllWith(tags, [{ fieldValue: 'null', totalCount: 0 }], _.isEqual);

  return (
    <Layout>
      <Helmet title={'Tom Beyer: ' + tag} />
      <h1>Blog: {tag}</h1>

      <Row>
        <Col>
          {data.pages.group.reverse().map((year) => (
            <div key={year.fieldValue}>
              <h2>{year.fieldValue}</h2>
              <PageList edges={year.edges} />
            </div>
          ))}
        </Col>
        <Col md="3">
          <ListGroup>
            {tags.map((tag) => (
              <ListGroupItem
                key={tag.fieldValue}
                className="justify-content-between d-flex align-items-center"
              >
                <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                  {tag.fieldValue}
                </Link>
                &nbsp;
                <Badge pill color="dark">
                  {tag.totalCount}
                </Badge>
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </Layout>
  );
};

export default Tags;

Tags.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  pageContext: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

Tags.defaultProps = {
  data: false,
  pageContext: false,
};

export const pageQuery = graphql`
  query($tag: String) {
    pages: allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      group(field: fields___year) {
        fieldValue
        edges {
          node {
            id
            frontmatter {
              title
              date(formatString: "YYYY-MM-DD")
              tags
            }
            fields {
              slug
              year
            }
          }
        }
      }
    }
    tags: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/blog/" } }) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
